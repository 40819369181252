import { AddToCartButton } from '@/components/general/AddToCartButton';
import { OptimizedImage } from '@/components/general/OptimizedImage';
import { leftFadeIn } from '@/constants/motionProps';
import { formatCurrency } from '@/utils/stringFormat';
import type { Product } from '@wavebi/tsunami-cms-admin';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import type { FC } from 'react';

export type CardProps = {
  currency?: string;
  allowAddToCart?: boolean;
  product: Product<'homeDecor1'>;
  backHref?: string;
  imageSize?: 'contain' | 'cover';
};

export const Card: FC<CardProps> = ({
  backHref,
  product,
  allowAddToCart,
  currency = 'CAD',
  imageSize = 'cover',
}) => {
  const {
    category,
    cartLinkToCategory,
    crmId,
    gallery,
    metadata,
    name,
    value: originalPrice,
    variantId,
  } = product;

  const href = `/products/${crmId}${backHref ? `?back=${backHref}` : ''}`;
  const [firstImage] = gallery;
  const isOnSale = metadata.is_onsale;
  const currentPrice = metadata.is_onsale
    ? Number.parseFloat(`${metadata.sale_value ?? originalPrice}`)
    : originalPrice;

  return (
    <motion.div
      variants={leftFadeIn}
      className={clsx([
        'flex',
        'flex-col',
        'group',
        'inline-block',
        'h-full',
        'border',
        'border-background-secondary',
        'transition-transform',
        'duration-500',
        'ease-out',
        'justify-between',
      ])}
    >
      <a
        href={href}
        className="flex flex-col gap-12 text-12 text-text-primary lg:text-14"
      >
        <div className="relative aspect-square  gap-12 overflow-hidden bg-skeleton">
          {!!firstImage && (
            <OptimizedImage
              src={firstImage?.src}
              alt={firstImage?.alt}
              small={[
                firstImage.optimized?.avif.small,
                firstImage.optimized?.webp.small,
                firstImage.optimized?.original.small,
              ]}
              imgProps={{
                className: clsx([
                  'size-full',
                  'object-center',
                  'transition',
                  'group-hover:scale-105',
                  'bg-white',
                  { 'object-contain': imageSize === 'contain' },
                  { 'object-cover': imageSize !== 'contain' },
                ]),
              }}
            />
          )}
        </div>

        <p className="px-8">{name}</p>
      </a>

      {!!allowAddToCart && (
        <div className="flex flex-col justify-between gap-2 px-8 py-12 text-12 text-text-primary lg:text-14">
          <div>
            {!!(isOnSale && currentPrice !== originalPrice) && (
              <p className="text-10 text-text-primary/50 line-through">
                {formatCurrency(originalPrice, {
                  options: { currency },
                })}
              </p>
            )}

            <p
              className={clsx([
                isOnSale &&
                  isOnSale &&
                  currentPrice !== originalPrice && [
                    'text-accent-1',
                    'font-medium',
                  ],
              ])}
            >
              {formatCurrency(currentPrice, {
                options: { currency },
              })}
            </p>
          </div>

          <AddToCartButton
            currency={currency}
            productId={crmId}
            variantId={variantId}
            name={name}
            price={originalPrice}
            img={firstImage}
            qty={1}
            metadata={metadata}
            addToCartBehaviour={{ type: 'default' }}
            discount={
              isOnSale
                ? {
                    type: 'percentage',
                    value:
                      1 -
                      Number.parseFloat(
                        `${metadata.sale_value ?? originalPrice}`
                      ) /
                        originalPrice,
                  }
                : undefined
            }
            cartLinkToCategory={cartLinkToCategory}
            category={category?.name ?? ''}
            className={clsx(['w-full', 'h-fit', '!px-16'])}
          >
            Add to Cart
          </AddToCartButton>
        </div>
      )}
    </motion.div>
  );
};
