import { SwiperButtons } from '@/components/general/SwiperButtons';
import { multipleChildren } from '@/constants/motionProps';
import type { Product } from '@wavebi/tsunami-cms-admin';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import type { FC } from 'react';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import type { CardProps } from '../Card';
import { Card } from '../Card';

export type CardsContainerProps = {
  currency?: string;
  products: Product<'homeDecor1'>[];
  backHref?: string;
  allowAddToCart?: boolean;
  slidesPerView?: number;
  cardImageSize?: CardProps['imageSize'];
};

export const CardsContainer: FC<CardsContainerProps> = ({
  allowAddToCart,
  backHref,
  products,
  cardImageSize = 'cover',
  currency = 'CAD',
  slidesPerView = 4,
}) => {
  const mobileSlidesPerView = 2;
  const desktopSlidesPerView =
    products.length >= slidesPerView ? slidesPerView : 4;

  return (
    <>
      {/* Mobile */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={multipleChildren}
        viewport={{ once: true }}
        className={clsx([
          'gap-12',
          'md:hidden',
          'grid-cols-2',
          { flex: products.length > mobileSlidesPerView },
          { grid: products.length <= mobileSlidesPerView },
        ])}
      >
        {products.length <= mobileSlidesPerView &&
          products.map((product, index) => {
            return (
              <Card
                key={index}
                backHref={backHref}
                product={product}
                currency={currency}
                allowAddToCart={allowAddToCart}
                imageSize={cardImageSize}
              />
            );
          })}

        {products.length > mobileSlidesPerView && (
          <Swiper
            modules={[Navigation]}
            loop
            spaceBetween={20}
            slidesPerView={mobileSlidesPerView}
          >
            {products.map((product, index) => {
              return (
                <SwiperSlide key={index}>
                  <Card
                    backHref={backHref}
                    product={product}
                    currency={currency}
                    allowAddToCart={allowAddToCart}
                    imageSize={cardImageSize}
                  />
                </SwiperSlide>
              );
            })}

            <div
              className={clsx([
                'absolute',
                'top-0',
                'left-0',
                'w-full',
                'aspect-[2/1]',
              ])}
            >
              <SwiperButtons />
            </div>
          </Swiper>
        )}
      </motion.div>

      {/* Desktop */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={multipleChildren}
        viewport={{ once: true }}
        className={clsx([
          'hidden',
          'gap-20',
          { 'md:flex': products.length > desktopSlidesPerView },
          { 'md:grid': products.length <= desktopSlidesPerView },
        ])}
        style={{ gridTemplateColumns: `repeat(${desktopSlidesPerView}, 1fr)` }}
      >
        {products.length <= desktopSlidesPerView &&
          products.map((product, index) => {
            return (
              <Card
                key={index}
                backHref={backHref}
                product={product}
                currency={currency}
                allowAddToCart={allowAddToCart}
                imageSize={cardImageSize}
              />
            );
          })}

        {products.length > desktopSlidesPerView && (
          <Swiper
            modules={[Navigation]}
            loop
            spaceBetween={20}
            slidesPerView={desktopSlidesPerView}
          >
            {products.map((product, index) => {
              return (
                <SwiperSlide key={index}>
                  <Card
                    backHref={backHref}
                    product={product}
                    currency={currency}
                    allowAddToCart={allowAddToCart}
                    imageSize={cardImageSize}
                  />
                </SwiperSlide>
              );
            })}

            <div
              className={clsx([
                'absolute',
                'top-0',
                'left-0',
                'w-full',
                'aspect-[2/1]',
                'lg:aspect-[3/1]',
              ])}
            >
              <SwiperButtons />
            </div>
          </Swiper>
        )}
      </motion.div>
    </>
  );
};
